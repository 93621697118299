import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiOutlineKey,
} from "react-icons/ai";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
        <Row>
          <Col md={12} className="home-about-social">
            <p>
                <span className="red">  </span> 
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="THE FIRST.png"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineKey />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="THE SECOND.png"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineKey />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="THE THIRD.png"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineKey />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="THE FOURTH.png"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineKey />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="THE FIFTH.png"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineKey />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="THE SIXTH.png"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineKey />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="THE SEVENTH.png"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineKey />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
    </Container>
  );
}
export default Home2;