import React, { useState } from 'react';
// import image from "../../Assets/login-img.jpg"; 
// // import { Link } from 'react-router-dom'
// // // import { useNavigate } from "react-router-dom";
// // import Container from "react-bootstrap/Container";
// // import Button from "react-bootstrap/Button";
// import { useNavigate } from "react-router-dom";

// function Login() {
//   // const navigateHome = () => {
//   //   // 👇️ navigate to /
//   //   // navigate('/');
//   // };

//   let navigate = useNavigate(); 
//   const routeChange = () =>{ 
//     let path = `/home`; 
//     if (document.getElementById("userInput").value === "1880") {
//       navigate(path);
//     } else {
//       alert("You may not enter. Tell me the year of our founding.");
//     }
//     // let guess = returnText();
//   }

//   return (
    
//     <div style={{
//           backgroundImage:`url(${image})`,
//           backgroundPosition: 'top',
//           backgroundRepeat: 'no-repeat',
//           backgroundSize: 'cover',
//           width: '100vw',
//           height: '100vh'}}
//           className="Login">
//           <p> ⠀ </p>
//           <p> ⠀ </p>
//           <p> ⠀ </p>
//           <p> ⠀ </p>
//           <p> ⠀ </p>
//           <p> ⠀ </p>
//           <p> ⠀ </p>
//           <input
//             id="userInput"
//             type="text"
//             placeholder="Password"
//             className="input"
//           />
//           {/* <button onClick="returnText()" className="button" >ENTER</button> */}

//           {/* <Link to="/home" className="button" >Sign up</Link> */}

//           {/* <button onClick="location.href='www.google.com'" className="button" id="myButton" >ENTER</button> */}
//           {/* <input type="button" className="button" onClick="location.href='/home'" value="click here to visit home page"></input>  */}
//           <p>
           
//           </p>
//           <p>
//           <button onClick={routeChange} className="button" id="myButton" >ENTER</button>
//           </p>
//           {/* <script type="text/javascript">
//             const btn = document.getElementById("button")
//             btn.addEventListener("click", function() {
//               if (document.getElementById("welcome1").value === "hi") {
//                 alert("Welcome");
//               } else {
//                 alert("See you later");
//               }
//             })
//           </script> */}



//           {/* <input type=button onClick="location.href='../'" value='click here to visit home page'></input> */}
//           {/* <Route path="/home" element={<Home />} /> */}
//         </div>
//     );
//   }
//   // return (
//   //   <div style={{
//   //     backgroundImage:`url(${image})`,
//   //     backgroundPosition: 'center',
//   //     backgroundRepeat: 'no-repeat',
//   //     backgroundSize: 'cover',
//   //     width: '100vw',
//   //     height: '100vh'}}>
//   //   </div>
//   // );

// export default Login;






// "You may not enter. Tell me the year of our founding."








import { useNavigate } from "react-router-dom";
import image from "../../Assets/login-img.jpg"; 

function Login() {
  const [isFocused, setIsFocused] = useState(false);
  let navigate = useNavigate(); 

  const routeChange = () => { 
    let path = `/home`; 
    if (document.getElementById("userInput").value === "1880") {
      navigate(path);
    } else {
      alert("Incorrect. Tell me the year of our founding.");
    }
  }

  // Inline styles
  const inputStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: 'rgba(255, 255, 255, 0.8)',
    border: '1px solid #fff',
    padding: '12px 20px',
    fontSize: '18px',
    fontFamily: '"Cinzel", serif',  // Gothic-style font
    borderRadius: '4px',
    boxShadow: '0 4px 8px rgba(255, 255, 255, 0.3)',
    margin: '20px 0',
    width: '280px',
    transition: '0.3s all ease-in-out',
    textAlign: 'center',
    textShadow: '0px 0px 5px rgba(255, 255, 255, 0.3)', // Text shadow for a spooky effect
  };

  const buttonStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: 'white',
    fontSize: '18px',
    fontFamily: '"Cinzel", serif',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgba(255, 255, 255, 0.4)',
    transition: '0.3s',
    margin: '10px',
    textShadow: '0px 0px 5px rgba(255, 255, 255, 0.7)',
  };

  return (
    <div style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff'
        }} className="Login">
      <input
        id="userInput"
        type="text"
        placeholder={!isFocused ? "PASSWORD" : ""}
        style={inputStyle}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <button onClick={routeChange} style={buttonStyle} id="myButton">
        ENTER
      </button>
    </div>
  );
}

export default Login;
