import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Amalgamator",
          "Sapient",
          "Vanguard",
          "Assiduous",
          "Imperator",
          "Theorist",
          "Pragmatist",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
